// 'use strict';

// class mainController {

//     user = {};
//     errors = {};
//     submitted = false;
//     //end-non-standard



//     constructor(Auth, $state, $scope) {
//         this.Auth = Auth;
//         this.$state = $state;
//         this.hideReset = false;
//         this.ismouseClick = false;
//         this.route = $state;
//         $scope.show = false;
//         $scope.showNote = false;
//         $scope.showHistory = false;

//         $scope.savedDetails = function(savedItem, index) {
//             $scope.selected = savedItem;
//             $scope.activeIndex = index;
//         };
//         $scope.isShowing = function(index) {
//             return $scope.activeIndex === index;
//         };
//         $scope.isShowingNotes = function(index) {
//             return $scope.activeIndex === index;
//         };
//         $scope.showSearchHistory = function() {
//             $scope.showHistory = true;
//             $('.search_history_block').css('width', $(".main_head_left").outerWidth() + $(".search_hist").outerWidth() + "px");
//         };
//         $scope.showNotes = function() {
//             $scope.showNote = true;
//             $scope.show = false;
//         };
//         $scope.showSavedSearch = function() {
//             $scope.showNote = false;
//             $scope.show = true;
//         };
//         $scope.hideDiv1 = function(e) {
//             //  alert($(".search_hist").hasClass("search_historylist"));
//         };
//         $scope.hideDiv = function() {
//             $scope.show = false;
//             $scope.showNote = false;
//             $scope.showHistory = false;
//         };
//         //searchhistory
//         $scope.searchhistory = [{
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '19 June, 2016, 4.05pm'
//         }, {
//             'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '19 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '19 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '19 June, 2016, 4.05pm'
//         }, {
//             'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
//             'date': '20 June, 2016, 4.05pm'
//         }, {
//             'title': 'Constitution of India: Article 32',
//             'date': '20 June, 2016, 4.05pm'
//         }];
//         $scope.savedItems = [{
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'Criminal',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'Dowry',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'Criminal',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'Criminal',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'Criminal',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'Criminal',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }, {
//             'head': 'My case related',
//             'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
//         }];


//         $scope.searchDetailList = [{
//             'cno': 1,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252',
//             'image': true
//         }, {
//             'cno': 2,
//             'court': 'Supreme Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 3,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 4,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252',
//             'image': true
//         }, {
//             'cno': 5,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 6,
//             'court': 'Supreme Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 7,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 8,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 9,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 10,
//             'court': 'Supreme Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 11,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 12,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 13,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 14,
//             'court': 'Supreme Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 15,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 16,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 17,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 18,
//             'court': 'Supreme Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 19,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }, {
//             'cno': 20,
//             'court': 'High Court',
//             'casehead': 'Constitution of India',
//             'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
//         }];

//     }
// }

// angular.module('kljDigitalLibraryApp')
//     .controller('mainController', mainController);
angular.module('kljDigitalLibraryApp')
    .controller('mainController', ["Auth", "$state", "$scope",
        function(Auth, $state, $scope) {
            user = {};
            errors = {};
            submitted = false;
            this.Auth = Auth;
            this.$state = $state;
            this.hideReset = false;
            this.ismouseClick = false;
            this.route = $state;
            $scope.show = false;
            $scope.showNote = false;
            $scope.showHistory = false;

            $scope.savedDetails = function(savedItem, index) {
                $scope.selected = savedItem;
                $scope.activeIndex = index;
            };
            $scope.isShowing = function(index) {
                return $scope.activeIndex === index;
            };
            $scope.isShowingNotes = function(index) {
                return $scope.activeIndex === index;
            };
            $scope.showSearchHistory = function() {
                $scope.showHistory = true;
                $('.search_history_block').css('width', $(".main_head_left").outerWidth() + $(".search_hist").outerWidth() + "px");
            };
            $scope.showNotes = function() {
                $scope.showNote = true;
                $scope.show = false;
            };
            $scope.showSavedSearch = function() {
                $scope.showNote = false;
                $scope.show = true;
            };
            $scope.hideDiv1 = function(e) {
                //  alert($(".search_hist").hasClass("search_historylist"));
            };
            $scope.hideDiv = function() {
                $scope.show = false;
                $scope.showNote = false;
                $scope.showHistory = false;
            };
            //searchhistory
            $scope.searchhistory = [{
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '19 June, 2016, 4.05pm'
            }, {
                'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '19 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '19 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '19 June, 2016, 4.05pm'
            }, {
                'title': 'Excercise of power: Issue of directions, guidelines and orders - Arts. 32 and 129',
                'date': '20 June, 2016, 4.05pm'
            }, {
                'title': 'Constitution of India: Article 32',
                'date': '20 June, 2016, 4.05pm'
            }];
            $scope.savedItems = [{
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'Criminal',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'Dowry',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'Criminal',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'Criminal',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'Criminal',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'Criminal',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }, {
                'head': 'My case related',
                'sa_details': 'Section Name, Advocate,Case Number,Citation, View Result List'
            }];


            $scope.searchDetailList = [{
                'cno': 1,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252',
                'image': true
            }, {
                'cno': 2,
                'court': 'Supreme Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 3,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 4,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252',
                'image': true
            }, {
                'cno': 5,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 6,
                'court': 'Supreme Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 7,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 8,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 9,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 10,
                'court': 'Supreme Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 11,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 12,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 13,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 14,
                'court': 'Supreme Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 15,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 16,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 17,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio...,  Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 18,
                'court': 'Supreme Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 19,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }, {
                'cno': 20,
                'court': 'High Court',
                'casehead': 'Constitution of India',
                'caNotes': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                'details': 'Article 32: Excercise of power: issue of directions, guidelines and orders - Arts.32 and - Protectio..., Matter relating to criminal intimidation..., (2009)8 SCC 252'
            }];

        }
    ]);